import React from 'react'
import { Link } from 'gatsby'
import { Button, Box, Heading, Text } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  author: {
    display: `inline-block`,
    color: `alpha`
  },
  occupation: {
    mb: 4
  },
  specialty: {
    color: `text`,
    mb: 4
  }
}

export default () => (
  <>
    <Heading variant='h1'>
      Selam, Ben <Text sx={styles.author}>Baran Saraçzade </Text>.
    </Heading>
    <Heading variant='h3' sx={styles.specialty}>
      Dijital Pazarlama, Kişisel Markalaşma, İş ve Ekosistem Geliştirme
    </Heading>
    <Box variant='buttons.group'>
      <Button as={Link} to='/iletisim'>
        İletişim
      </Button>
      <Button variant='white' as={Link} to='/hakkimda'>
        Hakkımda
      </Button>
    </Box>
  </>
)
